@import '@styles/config.scss';

.section-wrapper {
  padding: 3.2rem;
  @include themed {
    background-color: theme($color-brand-deep-blue);
  }

  @include website-xl-viewport {
    padding: 4rem 3.2rem;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem 4.8rem;
    flex-wrap: wrap;

    @include website-media(website-md-viewport, website-sm-viewport) {
      flex-direction: column;
    }

    .label {
      @include body-short-lg;
      @include themed {
        color: theme($color-neutral-bg-low);
      }

      @include website-media(website-md-viewport, website-sm-viewport) {
        text-align: center;
      }
    }

    .brands-container {
      display: flex;
      gap: 2.4rem 4.8rem;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      @include website-media(website-sm-viewport) {
        gap: 2.4rem;
      }

      .brand-icon {
        height: 3.2rem;
        @include website-sm-viewport {
          height: 2.4rem;
          width: 12rem;
        }
      }

      .link {
        line-height: 0;
      }
    }
  }
}
