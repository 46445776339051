/* stylelint-disable value-no-vendor-prefix */
@import '@styles/config.scss';

.main {
  .brands-section {
    @include website-media(website-lg-viewport, website-xl-viewport) {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 2;
    }
  }
}
