@import '@styles/config.scss';

@mixin icon-transition {
  transition: fill 0.5s ease-in, stroke 0.5s ease-in;
}

.section-container {
  min-height: 92rem;
  position: relative;
  overflow: hidden;

  @include themed {
    background-color: theme($color-primary-brand-base);
  }

  @include website-media(website-md-viewport) {
    min-height: 76.6rem;
  }

  @include website-media(website-sm-viewport) {
    min-height: 72rem;
  }

  .background-image {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
    object-fit: cover;
    object-position: top;

    @include website-sm-viewport {
      display: none;
    }

    &.talent-background-image {
      object-fit: 'cover';
      object-position: 'right top';
    }
  }

  .image-visible {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
  }

  &.talent-background {
    @include website-media(website-lg-viewport, website-xl-viewport) {
      .background-lines-container .icon {
        @include themed {
          fill: theme($color-highlight-alt-base);
          stroke: theme($color-highlight-alt-base);
        }
      }
    }
  }

  &.company-background {
    @include website-media(website-lg-viewport, website-xl-viewport) {
      .background-lines-container .icon {
        @include themed {
          fill: theme($color-highlight-base);
          stroke: theme($color-highlight-base);
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 10.6rem;
    text-align: center;
    position: absolute;
    @include website-media(website-md-viewport) {
      padding-top: 7.2rem;
    }
    @include website-media(website-sm-viewport) {
      padding-top: 4.8rem;
    }

    .title {
      padding: 4.8rem 2.4rem 3.2rem;
      @include heading-giant;
      @include themed {
        color: theme($color-neutral-bg-lowest);
      }

      @include website-media(website-md-viewport) {
        padding-bottom: 1.6rem;
        @include heading-display;
      }

      @include website-media(website-sm-viewport) {
        padding-bottom: 1.6rem;
        @include heading-xxxl;
      }
    }

    .description {
      padding: 0 2.4rem 11.2rem;
      @include website-body-xl;
      @include themed {
        color: theme($color-neutral-bg-lowest);
      }

      @include website-sm-viewport {
        padding-bottom: 4.6rem;
        @include body-long-lg;
      }

      @include website-md-viewport {
        padding-bottom: 6.4rem;
        @include website-body-lg;
      }
    }

    .talent-button {
      margin-bottom: 1.6rem;
    }

    .button {
      @include website-sm-viewport {
        display: none;
      }
    }

    .mobile-button {
      @include website-media(website-xl-viewport, website-lg-viewport, website-md-viewport) {
        display: none;
      }
    }
  }

  .background-lines-container {
    max-width: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    max-height: 60rem;
    position: absolute;
    z-index: 1;
    bottom: -7rem;
    pointer-events: none;

    @include website-media(website-xl-viewport) {
      min-width: 200rem;
      max-width: 220rem;
      left: 50%;
      transform: translate(-50%, 0);
      justify-content: space-between;
      overflow: visible;
    }

    @include website-media(website-md-viewport) {
      min-width: 100rem;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -10rem;
      justify-content: space-between;
    }

    @include website-media(website-sm-viewport) {
      min-width: 72rem;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -16rem;
      justify-content: space-between;
    }

    .icon {
      position: relative;
      @include icon-transition;

      @include themed {
        fill: theme($color-secondary-brand-base);
        stroke: theme($color-secondary-brand-base);
      }

      &.background-lines-left {
        left: -15rem;

        @include website-media(website-md-viewport) {
          left: -7rem;
        }
        @include website-media(website-sm-viewport) {
          left: 0;
        }
      }

      &.background-lines-right {
        right: -10rem;
        top: 3rem;

        @include website-media(website-md-viewport) {
          right: -7rem;
        }
        @include website-media(website-sm-viewport) {
          right: 0;
        }
      }
    }
  }
}
